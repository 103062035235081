import React, { useState } from "react"
import PropTypes from "prop-types"
import CookieModalSwitch from "./cookieModalSwitch"
import CookieModalTextInfo from "./cookieModalTextInfo"
import {
  acceptAllCookies,
  acceptedAds,
  acceptedAudience,
  acceptedSocialNetwork,
  refuseAllCookies,
  setCookiesConsent,
} from "../../services/global/cookiesService"
import "./cookieForm.css"

const CookieForm = ({ afterSave, policyUrl, charteUrl }) => {
  const [acceptAnalytics, setAcceptAnalytics] = useState(false)
  const [acceptAds, setAcceptAds] = useState(false)
  const [acceptSocial, setAcceptSocial] = useState(false)

  const acceptAll = () => {
    acceptAllCookies()
    afterSave()
  }

  const refuseAll = () => {
    refuseAllCookies()
    afterSave()
  }

  const handleSave = () => {
    setCookiesConsent(acceptAnalytics, acceptAds, acceptSocial)
    afterSave()
  }

  return (
    <div className="container mgc-cookie-form">
      <div className="row no-gutters mgc-cookie-form">
        <div className="col-12 col-lg-8 px-3 pl-lg-3 pr-lg-2 text-justify">
          Ce site utilise plusieurs types de cookies. Ceux-ci ne sont déposés et
          lus sur votre terminal ou navigateur que si nous recueillons votre
          consentement. Nous vous invitons à consulter notre politique de
          cookies afin d’obtenir des informations sur la nature des cookies
          déposés et lus. En fonction de leurs finalités, vous pouvez les
          accepter ou les refuser. Les cookies fonctionnels / techniques,
          strictement nécessaires au bon fonctionnement du site, ne sont pas
          paramétrables.
        </div>
        <div className="col-12 col-lg-1 px-3 mt-2 pb-4"></div>
        <div className="col-12 col-lg-3 px-3 mt-2 pb-4">
          <div className="row">
            <div className="col display-center">
              <button
                role="button"
                className="col-12 btn btn-success"
                onClick={() => {
                  acceptAll()
                }}
              >
                Accepter et continuer
              </button>
            </div>
          </div>
          <div className="row mt-10px"></div>
          <div className="row">
            <div className="col display-center">
              <button
                className="col-12 btn btn-danger"
                onClick={() => {
                  refuseAll()
                }}
              >
                Refuser et fermer
              </button>
            </div>
          </div>
        </div>
      </div>

      <CookieModalSwitch
        title={"Cookies fonctionnels / techniques"}
        checked={true}
        onColor={"#7F7F7F"}
        disabled={true}
        infoText={
          "Ils sont indispensables au bon fonctionnement du site et strictement nécessaires à la fourniture du service de communication en ligne ou de l’information expressément demandés par l’utilisateur."
        }
      />

      <CookieModalSwitch
        title={"Cookies tiers de mesure d’audience"}
        onChange={checked => setAcceptAnalytics(checked)}
        checked={acceptedAudience()}
        infoText={
          <CookieModalTextInfo
            text={`Ces cookies nous permettent de générer des
              statistiques sur la façon dont les visiteurs utilisent le site.
              Notre objectif est d’améliorer l’ergonomie et le contenu
              du site. Ces cookies ne sont pas utilisés à des fins
              commerciales. Nous utilisons les services de deux
              fournisseurs pour ce type de cookies.`}
            textSecondary={
              <div>
                Pour plus d’informations, nous vous invitons à consulter les
                politiques des partenaires suivants :<br></br>
                <ul className={"modal-list"}>
                  <li>
                    AT Internet.{" "}
                    <a
                      href={"/politique-gestion-cookies/"}
                      className={"modal-link"}
                    >
                      Consultez la politique de cookies
                    </a>
                  </li>
                  <li>
                    Google Analytics Consultez la politique de confidentialité
                    de Google :{" "}
                    <a
                      href={
                        "https://policies.google.com/technologies/types?hl=fr"
                      }
                      className={"modal-link"}
                    >
                      https://policies.google.com/technologies/types?hl=fr
                    </a>
                  </li>
                  <li>Cywyc (fournisseur du service de Chat en ligne).</li>
                </ul>
              </div>
            }
          />
        }
      />
      <CookieModalSwitch
        title={"Cookies publicitaires"}
        checked={acceptedAds()}
        onChange={checked => setAcceptAds(checked)}
        infoText={`Ces cookies nous permettent d’afficher de la publicité 
ciblée. En refusant ce type de cookie, cela ne signifie 
pas que vous ne verrez pas de publicité MGC mais que 
cette publicité ne sera pas personnalisée. 
            `}
      />
      <CookieModalSwitch
        title={"Cookies de réseaux sociaux"}
        checked={acceptedSocialNetwork()}
        onChange={checked => setAcceptSocial(checked)}
        infoText={
          <CookieModalTextInfo
            text={`Ces cookies sont apposés sur le site par des tiers et vous
              permettent, le cas échéant avec votre accord, de nous suivre via
              nos pages sur les réseaux sociaux. Sur le site certains boutons
              de partage sont intégrés via des applications tierces pouvant
              émettre ce type de cookies.`}
            textSecondary={
              <div>
                Pour plus d’informations, nous vous invitons à consulter les
                politiques des réseaux sociaux suivants :
                <ul className={"modal-list"}>
                  <li>
                    Facebook :{" "}
                    <a
                      href={"https://www.facebook.com/policies/cookies/"}
                      className={"modal-link"}
                    >
                      https://www.facebook.com/policies/cookies/
                    </a>
                  </li>
                  <li>
                    Twitter :{" "}
                    <a
                      href={"https://twitter.com/privacy?lang=fr"}
                      className={"modal-link"}
                    >
                      https://twitter.com/privacy?lang=fr
                    </a>
                  </li>
                  <li>
                    LinkedIn :{" "}
                    <a
                      href={"https://www.linkedin.com/legal/cookie-policy"}
                      className={"modal-link"}
                    >
                      https://www.linkedin.com/legal/cookie-policy
                    </a>
                  </li>
                  <li>
                    Youtube :{" "}
                    <a
                      href={"https://policies.google.com/privacy?hl=fr&gl=fr"}
                      className={"modal-link"}
                    >
                      https://policies.google.com/privacy?hl=fr&gl=fr
                    </a>
                  </li>
                </ul>
              </div>
            }
          />
        }
      />
      <div className="row no-gutters text-center mt-3">
        <div className="col-12">
          <button
            role={"button"}
            className={"btn btn-save"}
            onClick={handleSave}
          >
            Enregistrer et fermer
          </button>
        </div>
        <div className="col-12 m-top-10">
          <a href={policyUrl} className={"policy-link"}>
            Politique de cookies
          </a>
        </div>
        <div className="col-12 m-top-10">
          <a href={charteUrl} className={"policy-link"}>
            Charte de protection des données personnelles
          </a>
        </div>
      </div>
    </div>
  )
}
CookieForm.propTypes = {
  handleHide: PropTypes.func,
  policyUrl: PropTypes.string,
  charteUrl: PropTypes.string,
}

CookieForm.defaultProps = {
  afterSave: () => {},
  policyUrl: "/politique-gestion-cookies/",
  charteUrl: "/charte-protection-des-donnees/",
}

export default CookieForm

import React, { useState } from "react"
import Layout from "../components/layout"
import CookieForm from "../components/global/cookieForm"

const GestionDesCookies = () => {
  const [hideAlertMessage, setHideAlertMessage] = useState(true)

  const afterSave = () => {
    setHideAlertMessage(false)
    if (typeof window !== "undefined") {
      window.location = "/"
    }
  }

  return (
    <Layout titleSeo={"Gestion des cookies"}>
      <section className="pageHeader">
        <div className="pageHeaderContainer">
          <nav>
            <ul className="breadcrumb">
              <li className="breadcrumbItem">
                <a
                  href="/"
                  className="breadcrumbItemLink breadcrumbItemLinkHome"
                >
                  <i className="icon-mgc" />
                </a>
              </li>
              <li className="breadcrumbItem" key="/contact">
                <a
                  className="breadcrumbItemLink breadcrumbItemLinkMiddle"
                  href="/gestion-des-cookies"
                >
                  Gestion des cookies
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </section>
      <section className="pageHeader callback">
        <div>
          <h1 className="pageHeaderTitle">Gestion des cookies</h1>
        </div>
      </section>
      <section>
        <div
          className="alert alert-success"
          role="alert"
          hidden={hideAlertMessage}
        >
          Votre demande a bien été prise en compte.
        </div>
        <CookieForm afterSave={afterSave} charteUrl={"/charte-protection-des-donnees/"}/>
        <div className="mt-3" />
      </section>
    </Layout>
  )
}

export default GestionDesCookies
